<template>
  <div class="hello" style="min-height: 100vh; background-color: #f3f3f3">
    <van-sticky>
      <van-nav-bar
        style="background: rgba(49, 151, 253, 1); color: #ffffff"
        @click-left="onClickLeft"
      >
        <template #title
          ><span style="color: #ffffff">{{ page_title }}</span></template
        >
        <template #left
          ><span style="color: #ffffff">
            <van-icon
              name="arrow-left"
              style="color: #ffffff; margin-right: 6px"
            />返回</span
          ></template
        >
      </van-nav-bar>
    </van-sticky>
    <div style="margin: 10px 10px">
      <van-cell-group inset title="定制需求">
        <van-field
          v-model="tmpData.content.destination"
          label="航线"
          required
          maxlength="20"
          placeholder="如：重庆到宜昌"
        />
        <van-field label="出行人数" required placeholder="如：重庆到宜昌">
          <template #input>
            <van-stepper v-model="tmpData.content.num" :min="1" />
          </template>
        </van-field>
        <van-field
          v-model="tmpData.godate"
          is-link
          readonly
          required
          name="datePicker"
          label="出行日期"
          placeholder="点击选择"
          @click="showPicker = true"
        />
        <van-field
          v-model="tmpData.content.info"
          label="其他要求"
          type="textarea"
          autosize
          maxlength="100"
          show-word-limit
          placeholder="如有其他要求，请在此填写,最多100字"
        />
      </van-cell-group>

      <van-cell-group inset title="联系人信息">
        <van-field
          v-model="tmpData.contact.name"
          required
          label="称呼"
          maxlength="10"
          placeholder="请输入您的称呼"
        />
        <van-field
          v-model="tmpData.contact.phone"
          required
          label="手机号"
          maxlength="11"
          type="digit"
          placeholder="请输入手机号"
        />
        <van-field
          v-model="tmpData.contact.code"
          required
          label="验证码"
          type="digit"
          maxlength="6"
          center
          placeholder="填写验证码"
        >
          <template #button>
            <van-button
              size="small"
              type="primary"
              :disabled="disabledBtn"
              @click="getImgUrl"
              >{{ btnTxt }}</van-button
            >
          </template>
        </van-field>
        <van-field
          v-model="tmpData.contact.else"
          label="其他"
          maxlength="20"
          placeholder="QQ或微信,20字内"
        />
      </van-cell-group>

      <div style="margin: 16px">
        <van-button
          round
          block
          type="primary"
          :loading="loading"
          loading-type="spinner"
          @click="submitOrder"
        >
          提交需求
        </van-button>
      </div>
    </div>
    <van-popup v-model:show="showPicker" position="bottom">
      <van-date-picker
        v-model="currentDate"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>

    <van-popup
      v-model:show="showImgCode"
      round
      position="center"
      :style="{ minHeight: '120px' }"
    >
    <div style="text-align: center;height: 50px;line-height: 50px;border-bottom: 1px solid #dedede;">点击图片可刷新</div>
    <div style="padding:10px;">
      <van-image style="width: 100%;" @click="getImgUrl" :src="imgUrl"></van-image>
    </div>
    <van-field
          v-model="img_code"
          center
          clearable
          required
          :maxlength="5"
          placeholder="验证码不区分大小写"
        >
    </van-field>
      <div style="padding:30px 20px;text-align: center;">
        <van-button type="default" block :loading="loading" @click="sendSms">获取验证码</van-button>
      </div>
    </van-popup>

    <div style="height: 80px;"></div>
    <my-footer :view="true" :showElse="false" :showTabBar="true"></my-footer>
  </div>
</template>
<script>
import { ref } from "vue";
import MyFooter from "@/components/myFooter.vue";

import {
  showNotify,
  showToast,
  BarrageProps,
  BarrageItem,
  BarrageInstance,
} from "vant";
import MyBox from "@/components/MyBox.vue";
export default {
  name: "makeOrder",
  components: {
    MyBox,
    MyFooter
  },
  data() {
    return {
      hasLoad: false,
      loading: false,
      currentDate: [],
      disabledBtn: false,
      showImgCode:false,
      imgUrl:false,
      img_code:'',
      btnTxt: "发送验证码",
      tmpData: {
        godate: "",
        content: {
          destination: "",
          num: 1,
          info: "",
        },
        contact: {
          name: "",
          phone: "",
          code: "",
          else: "",
        },
      },
      page_title: "",
      showPicker: false,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 5, 1),
    };
  },
  setup() {
    const onClickLeft = () => history.back();
    return {
      onClickLeft,
    };
  },
  mounted() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    let d = new Date();
    this.minDate = new Date(d.getFullYear(), d.getMonth(), d.getDate());
    this.maxDate = new Date(d.getFullYear() + 1, d.getMonth(), d.getDate());
    this.currentDate = [d.getFullYear(), d.getMonth(), d.getDate()];
    this.page_title = `游轮包租`;
  },
  methods: {
    getImgUrl(){
      if(!this.checkMobile(this.tmpData.contact.phone)){
        showToast('请填写正确的手机号')
        return
      }
      let timeStamp = new Date().getTime()
      this.imgUrl = '/wechat/Index/captcha?ts='+timeStamp
      this.showImgCode = true
      this.img_code = ''
    },
    checkMobile(phone){
      if(/^1\d{10}$/.test(phone)){
        return true;
      }
      return false
    },
    sendSms() {
      if (!this.checkMobile(this.tmpData.contact.phone)) {
        showToast("请填写正确的手机号");
        return;
      }
      this.loading = true;
      this.$http
        .postData("/Index/sendSms", {
          phone: this.tmpData.contact.phone,
          scen: "bc",
          hide_success: false,
          img_code:this.img_code
        })
        .then((res) => {
          this.loading = false;
          this.openTimer();
          this.img_code = ''
          this.showImgCode = false
        })
        .catch((error) => {
          this.loading = false;
          this.img_code = ''
          this.getImgUrl()
        });
    },
    openTimer() {
      let sec = localStorage.getItem("second")
        ? localStorage.getItem("second")
        : 60;
      let _this = this;
      this.timer = setInterval(() => {
        if (sec > 1) {
          sec -= 1;
          _this.disabledBtn = true;
          _this.btnTxt = sec + "秒";
        } else {
          clearInterval(_this.timer);
          _this.disabledBtn = false;
          _this.btnTxt = "发送验证码";
        }
      }, 1000);
    },
    onConfirm(val) {
      this.tmpData.godate = val.selectedValues.join("-");
      this.showPicker = false;
    },
    submitOrder() {
      if (!this.tmpData.content.destination) {
        showToast("请填写航线");
        return;
      }
      if (this.tmpData.content.num < 1) {
        showToast("出行人数至少1名");
        return;
      }
      if (!this.tmpData.godate) {
        showToast("请选择出行日期");
        return;
      }
      if (!this.tmpData.contact.name) {
        showToast("请填写您的称呼");
        return;
      }
      if(!this.checkMobile(this.tmpData.contact.phone)){
        showToast('联系人手机号错误')
        return
      }
      if(!/^\d{6}$/.test(this.tmpData.contact.code)){
        showToast('短信验证码格式错误')
        return
      }
      this.loading = true;
      let _this = this;
      this.$http
        .postData("/Baochuan/apply", { ...this.tmpData, scen: "bc" })
        .then((res) => {
          this.loading = false;
          showToast("提交成功");
          setTimeout(function () {
            _this.$router.push("/");
          }, 1200);
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>
  <style scoped>
</style>
  