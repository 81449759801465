<template>
  <div class="hello" style="min-height: 100vh; background-color: #f3f3f3">
    <van-sticky>
      <van-nav-bar
        style="background: rgba(49, 151, 253, 1); color: #ffffff"
        @click-right="logOut"
      >
        <template #title
          ><span style="color: #ffffff">{{ "我的订单" }}</span></template
        >
        <template #right
          ><span style="color: #ffffff">
            退出
            <van-icon
              name="close"
              style="color: #ffffff; margin-right: 6px" /></span
        ></template>
      </van-nav-bar>
      <van-tabs v-model:active="active" @change="selectTab">
        <van-tab
          v-for="(tab, idx) in tabs"
          :title="tab.title"
          :key="idx"
        ></van-tab>
      </van-tabs>
    </van-sticky>
    <van-list
      :loading="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="getList"
    >
      <div class="box">
        <div
          class="item"
          v-for="item in dataList"
          @click="viewItem(item.id)"
          :key="item.id"
        >
          <div class="title">
            单号 {{ item.order_sn }}
            <span class="tags">
              <van-tag type="danger" v-if="item.status == 1">{{
                item.status_txt
              }}</van-tag>
              <van-tag type="warning" v-else-if="item.status == 2">{{
                item.status_txt
              }}</van-tag>
              <van-tag type="success" v-else-if="item.status == 3">{{
                item.status_txt
              }}</van-tag>
              <van-tag type="warning" v-else-if="item.status == 4">{{
                item.status_txt
              }}</van-tag>
              <van-tag type="default" v-else>{{
                item.status_txt
              }}</van-tag>
            </span>
          </div>
          <div class="content">
            <div>
              <van-image class="thumb" fit="cover" :src="item.room.thumb">
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>
            </div>
            <div class="right">
              <van-text-ellipsis :content="item.title" />
              <div>
                金额:
                <span class="price"
                  >￥<b>{{ item.total }}</b></span
                >
              </div>
              <div>出行日期:{{ item.godate }}</div>
            </div>
          </div>
          <div class="footer">
            {{ item.create_time }}
            <van-button
              size="mini"
              v-if="item.status == 1"
              style="float: right"
              plain
              hairline
              type="danger"
              >去支付</van-button
            >
          </div>
        </div>
      </div>
    </van-list>
    <div style="height: 80px;"></div>
    <my-footer :view="hasLoad" :showTabBar="true" :showElse="false"></my-footer>
  </div>
</template>
<script>
import { ref } from "vue";
import { showToast, showConfirmDialog } from "vant";
import MyFooter from "@/components/myFooter.vue";

export default {
  name: "user",
  components: {
    MyFooter
  },
  data() {
    return {
      hasLoad: false,
      catalog: false,
      active: 0,
      tags: false,
      loading: false,
      finished: false,
      error: false,
      dataList: [],
      per_page: 10,
      last_page: 0,
      current_page: 0,
      total: 0,
      tag: "all",
      tabs: [
        {
          title: "全部",
          tag: "all",
        },
        {
          title: "待付款",
          tag: "unpay",
        },
        {
          title: "待确认",
          tag: "pay",
        },
        {
          title: "成功",
          tag: "success",
        },
        {
          title: "失败",
          tag: "fail",
        },
      ],
    };
  },
  setup() {
    const onClickLeft = () => history.back();
    const activeName = ref("b");
    return {
      onClickLeft,
      activeName,
    };
  },
  mounted() {
    this.getList();
    this.hasLoad = true
  },
  methods: {
    selectTab(val) {
      this.tag = this.tabs[val].tag;
      this.dataList = [];
      this.finished = false;
      this.current_page = 0;
      this.total = 0;
      this.last_page = 0;
      this.getList();
    },
    viewItem(item) {
      if (!item) return;
      let url = "/order/detail/" + item;
      this.$router.push(url);
    },
    getList() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.$http
        .postData("/index/getOrderList", {
          table: "Order",
          tag: this.tag,
          pageInfo: {
            page_size: this.per_page,
          },
          page: this.current_page + 1,
        })
        .then((res) => {
          this.loading = false;
          res.data.map((item) => {
            this.dataList.push(item);
          });
          this.current_page = res.current_page;
          this.total = res.total;
          if (res.last_page == res.current_page || res.last_page == 0) {
            this.finished = true;
            return;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.finished = true;
        });
    },
    logOut() {
      showConfirmDialog({
        title: false,
        message: "确认退出吗？",
      })
        .then(() => {
          localStorage.removeItem("userInfo");
          localStorage.removeItem("openid");
          localStorage.removeItem("mini");
          this.$router.push({ path: "/" });
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>
  <style scoped>
.tops {
  text-align: center;
  margin: 20px;
}
.tops .title {
  font-size: 16px;
  line-height: 30px;
}
.tops .info {
  font-size: 12px;
  line-height: 30px;
  color: #666666;
}
.box {
}
.item {
  margin: 20px;
  background: #fff;
  padding: 10px;
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.item .title {
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 6px;
  position: relative;
}
.item .title .tags {
  position: absolute;
  z-index: 1;
  right: 0px;
  top: -6px;
}
.item .content {
  font-size: 12px;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
}
.item .right {
  padding-left: 20px;
  flex: 1;
  line-height: 24px;
  color: #666;
}

.item .content .thumb {
  width: 60px;
  height: 60px;
  border-radius: 6px;
}
.item .footer {
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  color: #999;
  border-top: 1px solid #f3f3f3;
  padding-top: 6px;
}
</style>
  