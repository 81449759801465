<template>
  <div class="hello" style="min-height: 100vh; background-color: #f3f3f3">
    <div style="padding: 20px">
      <van-cell-group inset>
        <div
          style="
            text-align: center;
            height: 50px;
            line-height: 50px;
            border-bottom: 1px solid #f3f3f3;
            margin: 0 0 10px 0;
          "
        >
          请登陆
        </div>
        <van-field
          v-model="login.account"
          clearable
          required
          type="digit"
          :maxlength="11"
          label="手机号"
          placeholder="请填写手机号"
        />
        <van-field
          v-model="login.code"
          center
          clearable
          required
          :maxlength="6"
          type="digit"
          label="验证码"
          placeholder="请输入验证码"
        >
          <template #button>
            <van-button
              size="small"
              type="primary"
              :disabled="disabledBtn"
              @click="getImgUrl"
              >{{ btnTxt }}</van-button
            >
          </template>
        </van-field>
      </van-cell-group>
      <div style="margin: 16px">
        <van-button
          round
          block
          :loading="loading"
          loading-type="spinner"
          type="primary"
          @click="toLogin"
        >
          登　陆
        </van-button>
      </div>
    </div>
    <my-footer :view="true" :showElse="false" :showTabBar="true"></my-footer>

    <van-popup
      v-model:show="showImgCode"
      round
      position="center"
      :style="{ minHeight: '120px' }"
    >
    <div style="text-align: center;height: 50px;line-height: 50px;border-bottom: 1px solid #dedede;">点击图片可刷新</div>
    <div style="padding:10px;">
      <van-image style="width: 100%;" @click="getImgUrl" :src="imgUrl"></van-image>
    </div>
    <van-field
          v-model="login.img_code"
          center
          clearable
          required
          :maxlength="5"
          placeholder="验证码不区分大小写"
        >
    </van-field>
      <div style="padding:30px 20px;text-align: center;">
        <van-button type="default" block :loading="loading" @click="sendSms">获取验证码</van-button>
      </div>
    </van-popup>
  </div>
</template>
  
<script>
import { showToast } from "vant";
import MyFooter from "@/components/myFooter.vue";

export default {
  name: "Login",
  components:{
    MyFooter
  },
  data() {
    return {
      loading: false,
      showImgCode:false,
      disabledBtn: false,
      btnTxt: "发送验证码",
      timer: false,
      login: {
        account: "",
        code: "",
        img_code:''
      },
      imgUrl:false
    };
  },
  mounted() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    getImgUrl(){
      if(!this.checkMobile(this.login.account)){
        showToast('手机号错误')
        return
      }
      let timeStamp = new Date().getTime()
      this.imgUrl = '/wechat/Index/captcha?ts='+timeStamp
      this.showImgCode = true
    },
    checkMobile(phone){
      if(/^1\d{10}$/.test(phone)){
        return true;
      }
      return false
    },
    sendSms() {
      if(!this.login.img_code){
        showToast('请填写图形验证码')
        return
      }
      if(!this.checkMobile(this.login.account)){
        showToast('手机号错误')
        return
      }
      if(this.loading){
        return
      }
      this.loading = true;
      this.$http
        .postData("/Index/sendSms", {
          phone: this.login.account,
          img_code: this.login.img_code,
          scen: "login",
          hide_success: false,
        })
        .then((res) => {
          this.loading = false;
          this.showImgCode = false
          this.login.img_code = ''
          this.openTimer();
        })
        .catch((error) => {
          this.login.img_code = ''
          this.getImgUrl()
          this.loading = false;
        });
    },
    openTimer() {
      let sec = localStorage.getItem("second")
        ? localStorage.getItem("second")
        : 60;
      let _this = this;
      this.timer = setInterval(() => {
        if (sec > 1) {
          sec -= 1;
          _this.disabledBtn = true;
          _this.btnTxt = sec + "秒";
        } else {
          clearInterval(_this.timer);
          _this.disabledBtn = false;
          _this.btnTxt = "发送验证码";
        }
      }, 1000);
    },
    toLogin() {
      if(!this.checkMobile(this.login.account)){
        showToast('手机号错误')
        return
      }
      if(!/^\d{6}$/.test(this.login.code)){
        showToast('短信验证码格式错误')
        return
      }
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.$http
        .loginByPhone({ ...this.login, scen: "login" })
        .then((res) => {
          this.loading = false;
          localStorage.setItem("userInfo", JSON.stringify(res));
          let redirect_url = localStorage.getItem("redirect_url");
          if (redirect_url) {
            localStorage.removeItem("redirect_url");
            this.$router.push({ path: redirect_url });
          } else {
            this.$router.push("/");
          }
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
  },
};
</script>
<style scoped>
</style>
  