<template>
  <div class="hello">
    <van-empty v-if="loading" description="请稍后" />
    <van-sticky v-else>
      <div class="topNav" v-if="logo">
        <van-image :src="logo" class="logo"></van-image>
      </div>
    </van-sticky>
    <!-- <van-space direction="vertical" fill> -->
    <div v-for="(obj, idx) in componentsArr" :key="idx">
      <!-- 宫格排列 -->
      <my-box
        :title="obj.title"
        :hideTitle="obj.config.hideTitle"
        :hideBorder="obj.config.hideBorder"
        :needMargin="obj.config.needMargin"
      >
        <template #title>
          <van-icon
            name="good-job-o"
            style="color: #5566ff; margin-right: 6px; font-size: 20px"
          />{{ obj.title }}
        </template>
        <template #content>
          <template v-if="obj.type == 'grid'">
            <!-- 菜单 -->
            <van-grid
              v-if="obj.tag == 'entery'"
              :square="obj.config.square"
              :border="obj.config.border"
              :icon-size="obj.config.icon_size ? obj.config.icon_size : ''"
              :column-num="obj.config.column ? obj.config.column : 4"
            >
              <van-grid-item v-for="(item, i) in obj.content" :key="i">
                <!-- 自定义 -->
                <div class="entery_item" @click="goUrl(item.url)">
                  <div
                    class="img"
                    :style="{
                      background: item.icon_color ? item.icon_color : '#f3f3f3',
                      fontSize: item.icon_size ? item.icon_size : '28px',
                      width: item.size ? item.size : '50px',
                      height: item.size ? item.size : '50px',
                    }"
                  >
                    <van-icon :name="item.icon" />
                  </div>
                  <div>{{ item.title }}</div>
                </div>
              </van-grid-item>
            </van-grid>
            <!-- 游船系列 -->
            <van-grid
              v-else
              :square="obj.config.square"
              :border="obj.config.border"
              :icon-size="obj.config.icon_size ? obj.config.icon_size : ''"
              :column-num="obj.config.column ? obj.config.column : 4"
            >
              <van-grid-item
                v-for="(item, i) in obj.content"
                :key="i"
                :icon="item.logo ? item.logo : 'photo-o'"
                :text="item.title"
                @click="goUrl(item.url)"
              />
            </van-grid>
          </template>
          <!-- banner -->
          <template v-else-if="obj.type == 'banner'">
            <van-swipe :autoplay="3000" lazy-render>
              <van-swipe-item v-for="(item, i) in obj.content" :key="i">
                <van-image
                  fit="cover"
                  :src="item.url"
                  width="100%"
                  :height="obj.height"
                ></van-image>
                <div>{{ obj.banner_height }}</div>
              </van-swipe-item>
            </van-swipe>
          </template>
          <template v-if="obj.type == 'notice'">
            <!-- 滚动公告 -->
            <van-notice-bar
              v-if="obj.tag == 'a'"
              left-icon="volume-o"
              :color="obj.color" :background="obj.background"
              :scrollable="false"
            >
              <van-swipe
                vertical
                class="notice-swipe"
                :autoplay="obj.times ? obj.times : 3000"
                :touchable="false"
                :show-indicators="false"
              >
                <van-swipe-item
                  v-for="(item, i) in obj.content"
                  :key="i"
                  @click="viewNotice(item)"
                  >{{ item.title }}</van-swipe-item
                >
              </van-swipe>
            </van-notice-bar>
            <van-notice-bar
              left-icon="volume-o"
              v-if="obj.tag == 'b'"
              :text="obj.content"
            />
          </template>
          <template v-if="obj.type == 'callPhone'">
            <!-- 拨打电话 -->
            <div class="callphone">
              <div @click="callPhone(obj.phone)"><van-icon name="phone-o" style="color: #ff0000;" /> {{obj.phone_title}} | <b>{{ obj.phone }}</b></div>
              <div @click="goUrl(obj.chat.url)"><van-icon name="chat-o" dot /> {{ obj.chat.title }}</div>
            </div>
          </template>
          <!-- 搜索面板 -->
          <template v-else-if="obj.type == 'search_box'">
            <div class="search_box" style="margin-top: -30px;background-color: #ffffff;">
              <div class="title">
                <div
                  class="item"
                  :class="searchForm.active == 1 ? 'item_act' : ''"
                  @click="chageSearch(1)"
                >
                  搜船期
                </div>
                <div
                  class="item"
                  :class="searchForm.active == 2 ? 'item_act' : ''"
                  @click="chageSearch(2)"
                >
                  搜游船
                </div>
              </div>
              <div class="search_line" v-if="searchForm.active == 1">
                <div class="tools">
                  <span class="start">
                    <van-popover @select="selectUpMatou" :actions="matou">
                      <template #reference>
                        {{ searchForm.up.text }}
                      </template>
                    </van-popover>
                  </span>
                  <span class="end">
                    <van-popover @select="selectDownMatou" :actions="matou">
                      <template #reference>
                        {{ searchForm.down.text }}
                      </template>
                    </van-popover>
                  </span>
                  <div class="trans" @click="changeStartEnd">
                    <font-awesome-icon icon="repeat" />
                  </div>
                </div>
                <div class="input_date">
                  日期 <span @click="showDateBox">{{ searchForm.date }}</span>
                </div>
                <div style="padding-top: 10px">
                  <van-button @click="searchDo" type="primary" block
                    >查询船期</van-button
                  >
                </div>
                <div class="hot">
                  热门
                  <span
                    v-for="(item, i) in obj.content.search_line"
                    :key="i"
                    @click="quickSearchLine(item)"
                    >{{ item.title }}</span
                  >
                </div>
              </div>
              <div class="search_line" v-if="searchForm.active == 2">
                <van-field v-model="searchForm.keyword" center size="large">
                  <template #input>
                    <input
                      type="text"
                      placeholder="输入游船名称"
                      v-model="searchForm.keyword"
                      class="inputs"
                    />
                    <van-button
                      @click="searchDo"
                      style="
                        margin: 0;
                        padding: 0 10px;
                        width: 90px;
                        border-radius: 0 6px 6px 0;
                        height: 40px;
                        line-height: 40px;
                        font-size: 14px;
                      "
                      size="large"
                      type="primary"
                      >搜索</van-button
                    >
                  </template>
                </van-field>
                <div style="margin-top: 10px">
                  <div class="stitle">三峡推荐游轮</div>
                  <van-row gutter="0">
                    <template v-for="(item, i) in obj.content.search_boat" :key="i">
                    <van-col
                      span="8"
                      v-if="i<6"
                    >
                      <div
                        style="
                          font-size: 12px;
                          line-height: 30px;
                          background: #f3f3f3;
                          padding: 0 6px;
                          border-radius: 5px;
                          margin: 5px;
                        "
                        @click="goUrl(item.url)"
                      >
                        {{ item.title }}
                      </div>
                    </van-col>
                  </template>
                  </van-row>
                </div>
              </div>
            </div>
          </template>
          <!-- 航线定制展示 -->
          <template v-else-if="obj.type == 'line_box'">
            <!-- 热门游船 -->
            <template v-if="obj.tag == 'hot_boat'">
              <van-card
                v-for="(item, i) in obj.content"
                :key="i"
                num="2"
                price="2.00"
                :tag="item.trans_txt"
                :thumb="item.boat_thumb"
                class="mlist"
                @click="goUrl(item.url)"
              >
                <template #title>
                  <van-text-ellipsis style="font-size: 1rem" rows="1" :content="item.title"/>
                </template>
                <template #tags>
                  <div v-if="item.tags && item.tags.length">
                    <van-tag
                      plain
                      type="danger"
                      style="margin-right: 4px"
                      v-for="(tag, t) in item.tags"
                      :key="t"
                      >{{ tag }}</van-tag
                    >
                  </div>
                </template>
                <template #desc>
                  <div style="margin: 6px 0" v-if="item.date_list">
                    <van-text-ellipsis rows="1" :content="'团期：'+item.date_list.join('、')"/>
                  </div>
                  <div style="margin: 6px 0" v-else>团期：-</div>
                </template>
                <template #num></template>
                <template #price>
                  <div style="margin:4px auto;">
                      <span class="price" v-if="item.mins">￥<b>{{ item.mins }}</b>起</span>
                      <span class="price" v-else><b>电询</b></span>
                  </div>
                </template>
              </van-card>
            </template>
            <!-- 推荐游船 -->
            <template v-else-if="obj.tag == 'tuijian_boat'">
              <van-row gutter="10" style="padding:10px;">
                <van-col span="12" v-for="(item, i) in obj.content" :key="i">
                  <div class="boat_item" @click="goUrl(item.mins?item.mins.url:item.url)">
                    <van-image fit="cover" :src="item.thumb" class="thumb">
                      <template v-slot:loading>
                        <van-loading type="spinner" size="20" />
                      </template>
                    </van-image>
                    <div class="title">
                      {{ item.title }}<van-tag type="warning" v-if="item.star">{{item.star}}</van-tag>
                    </div>
                    <div class="price_info" v-if="item.mins">
                      <span>￥<span class="price">{{ item.mins.price }}</span>起</span>
                    </div>
                    <div class="price_info" v-else>
                      <span><span class="price">电询</span></span>
                    </div>
                  </div>
                </van-col>
              </van-row>
            </template>
            <!-- 热门航线 -->
            <van-row v-else-if="obj.tag == 'hot_line'">
              <van-col span="12" v-for="(item, i) in obj.content" :key="i">
                <div class="hot_line" @click="quickSearchLine(item)">
                  <span class="start">{{ item.matou.up_info.title }}</span>
                  <div class="jt"><span class="arrow"></span></div>
                  <span class="end">{{ item.matou.down_info.title }}</span>
                  <div class="pcs" v-if="item.mins > 0">
                    ￥<span class="price">{{ item.mins }}</span>起
                  </div>
                  <div class="pcs" v-else><span class="price">电询</span></div>
                  <div class="trans">{{ item.trans_txt }}</div>
                </div>
              </van-col>
            </van-row>
            <van-cell-group v-else>
              <van-cell
                v-for="(item, i) in obj.content"
                :key="i"
                :title="item.title"
              />
            </van-cell-group>
          </template>
        </template>
      </my-box>
    </div>
    <van-calendar
      v-model:show="showdate"
      :show-confirm="false"
      @confirm="onConfirm"
    />
    <my-footer :view="showFooter" :showTabBar="true"></my-footer>
    <div class="van-safe-area-bottom"></div>
    <!-- </van-space> -->
  </div>
</template>
  
<script>
import { showConfirmDialog, showToast } from "vant";
import MyBox from "@/components/MyBox.vue";
import MyFooter from "@/components/myFooter.vue";
import wx from 'weixin-js-sdk'; // 引入微信JS-SDK库

export default {
  name: "index",
  components: {
    MyBox,
    MyFooter
  },
  data() {
    return {
      logo:false,
      userInfo: false,
      loading: false,
      showMatou: false,
      componentsArr: [],
      showdate: false,
      showFooter:false,
      searchForm: {
        active: 1,
        keyword: "",
        up: {
          id: 0,
          text: "重庆",
        },
        down: {
          id: 0,
          text: "宜昌",
        },
        date: "",
        lid: 0,
        bid: 0,
        st: "day",
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    selectUpMatou(item) {
      this.searchForm.up = item;
    },
    selectDownMatou(item) {
      this.searchForm.down = item;
    },
    showDateBox() {
      this.showdate = true;
    },
    onConfirm(date) {
      let m = date.getMonth() + 1;
      let d = date.getDate();
      this.searchForm.date = `${date.getFullYear()}-${m < 10 ? "0" + m : m}-${
        d < 10 ? "0" + d : d
      }`;
      this.showdate = false;
    },
    changeStartEnd() {
      let a = this.searchForm.up;
      let b = this.searchForm.down;
      this.searchForm.up = b;
      this.searchForm.down = a;
    },
    chageSearch(val) {
      this.searchForm.active = val;
    },
    init() {
      this.loading = true;
      this.$http
        .postData("/Boat/initData", {
          hide_success: true,
        })
        .then((res) => {
          this.loading = false;
          this.componentsArr = res.list;
          this.matou = res.matou;
          this.searchForm.date = res.date;
          res.matou.map((item) => {
            if (item.title == "重庆") {
              this.searchForm.up = item;
            }
            if (item.title == "宜昌") {
              this.searchForm.down = item;
            }
          });
          res.list.map(item=>{
            if(item.type=='search_box'){
              localStorage.setItem("hotBoat", JSON.stringify(item.content.search_boat));
            }
          })
          let typeArr = [];
          res.types.map((item) => {
            typeArr.push({
              id: item.id,
              title: item.title,
            });
          });
          localStorage.setItem("typeArr", JSON.stringify(typeArr));
          localStorage.setItem("monthArr", JSON.stringify(res.month));
          localStorage.setItem("matouArr", JSON.stringify(res.matou));
          localStorage.setItem("footer_links", JSON.stringify(res.footer_links));
          localStorage.setItem("copyright", res.copyright);
          localStorage.setItem("order_tips", res.order_tips);
          localStorage.setItem("room_tips", res.room_tips);

          if(res.need){
            localStorage.removeItem("tabData");
          }
          this.logo = res.logo?res.logo:false
          this.showFooter = true
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    login() {},
    goUrl(url) {
      if (!url) return;
      if (url == "not") {
        this.$notFound();
        return;
      }
      if (url.indexOf("http") > -1) {
        window.location.href = url;
        return;
      }
      this.$router.push(url);
    },
    callPhone(phoneNumber){
      if(phoneNumber){
        window.location.href = "tel://" + phoneNumber;
      }
    },
    viewNotice(item) {
      this.goUrl(item.url_font);
    },
    quickSearchLine(item) {
      this.searchForm.up = item.matou.up_info;
      this.searchForm.down = item.matou.down_info;
      this.searchForm.active = 1;
      this.searchDo();
    },
    searchDo() {
      localStorage.setItem("searchData", JSON.stringify(this.searchForm));
      if (this.searchForm.active == 1) {
        this.$router.push("/shipMent/day");
      } else {
        if (!this.searchForm.keyword) {
          showToast("请填写关键词");
          return;
        }
        this.$router.push({
          path: "/search",
          query: {
            kwd: this.searchForm.keyword,
          },
        });
      }
    },
  },
};
</script>
  <style scoped>
.hello {
  margin-bottom: 80px;
}
/* .price {
  color: #cc0000;
  font-size: 1rem;
  padding: 0 4px;
} */

.entery_item {
  text-align: center;
  font-size: 0.8rem;
  line-height: 2rem;
}
.entery_item .img {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0 auto;
  color: #ffffff;
}
.boat_item {
  font-size: 14px;
  margin: 10px 0;
}
.boat_item .title {
  line-height: 30px;
  padding: 0 10px;
}
.boat_item .thumb {
  width: 100%;
  height: 140px;
  border-radius: 6px;
  overflow: hidden;
}
.boat_item .price_info {
  color: #666;
  padding: 0 10px;
  font-size: 12px;
}
.boat_item .price {
  color: #ff5a00;
  font-size: 18px;
}
.search_box {
  position: relative;
  min-height: 100px;
  border-radius: 10px;
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.2);
}
.search_box .title {
  height: 40px;
  border-radius: 10px 10px 0 0;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-bottom: none;
  position: absolute;
  z-index: 1;
  top: -40px;
  left: 30px;
  right: 30px;
  overflow: hidden;
}
.search_box .title .item {
  height: 40px;
  text-align: center;
  line-height: 40px;
  background: #f8f8f8;
  width: 50%;
  float: left;
}
.search_box .title .item_act {
  background: #ffffff;
  color: #333333;
}
.search_line {
  padding: 10px 20px;
}
.search_line .input_date {
  font-size: 20px;
  padding: 10px 20px;
}
.search_line .input_date span {
  padding-left: 10px;
  color: #000000;
}
.search_line .tools {
  position: relative;
  font-size: 20px;
  padding: 10px;
  border-bottom: 1px solid #dedede;
}
.search_line .trans {
  width: 50px;
  margin: 0 auto;
  text-align: center;
  color: #2c92ff;
}
.search_line .start {
  position: absolute;
  left: 20px;
  top: 10px;
  font-weight: 500;
}
.search_line .end {
  position: absolute;
  right: 20px;
  top: 10px;
  font-weight: 500;
}
.search_line .hot {
  margin: 10px 0;
  line-height: 30px;
  color: #cc0000;
  font-size: 12px;
  height: 30px;
  overflow: hidden;
}
.search_line .hot span {
  margin: 0 5px;
  color: #666666;
}

.notice-swipe {
  height: 40px;
  line-height: 40px;
}
.topNav{
  background: #1989fa;
  text-align: center;
}
.topNav .logo{
  width:300px;
  margin:10px auto;
}
.topNav .wtitle{
  font-size: 16px;
  font-weight: 500;
}
.callphone{
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  border:1px solid #f3f3f3;
  padding:0 10px;
}
.callphone b{
  color:#ff0000;
  font-weight: 600;
}
.hot_line {
  height: 50px;
  line-height: 50px;
  background: #f3f3f3;
  border-radius: 10px;
  margin: 10px;
  position: relative;
  font-size: 12px;
}
.hot_line .start {
  position: absolute;
  left: 10px;
  top: 0px;
  line-height: 50px;
}
.hot_line .end {
  position: absolute;
  right: 10px;
  top: 0px;
  line-height: 50px;
}
.hot_line .trans {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 10px;
  line-height: 20px;
  text-align: center;
  font-size: 12px !important;
}
.hot_line .pcs {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 10px;
  line-height: 20px;
  text-align: center;
  font-size: 12px !important;
}
.hot_line .pcs .price {
  color: #ff5a00;
  font-size: 12px !important;
}

.jt {
  border-bottom: 1px solid #2c92ff;
  position: absolute;
  left: 34%;
  right: 34%;
  top: 16px;
  display: flex;
  justify-content: right;
}
.jt .arrow {
  position: relative;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 0px 10px;
  border-color: transparent transparent transparent #2c92ff;
  /* transform: rotate(45deg); */
}
.inputs {
  border: 1px solid #dedede;
  border-right: none;
  border-radius: 6px 0 0 6px;
  padding: 0 0 0 10px;
  height: 38px;
  line-height: 38px;
  color: #333333;
  width: 100%;
}
.mlist{
}
.mlist .price{
    font-size: 10px;
}
.mlist .price b{
    color: #ff5a00;
    font-size: 18px;
    font-weight: 300;
}
</style>
  