<template>
    <div>
      <van-back-top right="5vw" bottom="10vh" />
      <template v-if="view">
        <template v-if="showElse">
          <div class="links" v-if="footer_links">
            <span v-for="(link,i) in footer_links" :key="i" @click="goUrl(link)">{{ link.title }}</span>
          </div>
          <div class="copyright dibb" v-if="copyright" v-html="copyright"></div>
          <div class="pmt">
            <van-image fit="cover" src="/img/alipay.png" class="imm"></van-image>
            <van-image fit="cover" src="/img/wxpay.jpg" style="margin:0 10px;" class="imm"></van-image>
            <van-image fit="cover" src="/img/ysf.jpg" style="margin-right: 10px;" class="imm"></van-image>
            <van-image fit="cover" src="/img/yl.jpg" class="imm" style="width:48px;height:30px"></van-image>
          </div>
        </template>
        <van-tabbar v-if="showTabBar && tabData" :style="tabData.style" v-model="active" active-color="#ffffff">
          <van-tabbar-item
            :style="tab.style"
            v-for="(tab, idx) in tabData.list"
            :key="idx"
            :name="tab.name"
            :dot="tab.dot"
            :badge="tab.badge"
            @click="toPage(tab)"
          >
            <template #icon>
              <van-icon
                :name="tab.icon"
                :color="tab.icon_color ? tab.icon_color : ''"
                :size="tab.icon_size ? tab.icon_size : ''"
              />
            </template>
            <span
              :style="{
                color: tab.color ? tab.color : '',
                fontSize: tab.size ? tab.size : '',
              }"
              >{{ tab.title }}</span
            >
          </van-tabbar-item>
        </van-tabbar>

      </template>
    </div>
</template>
  
  <script>
  export default {
    name: "myFooter",
    props: {
      view:{
        type:Boolean,
        default:false
      },
      showTabBar:{
        type:Boolean,
        default:false
      },
      showElse:{
        type:Boolean,
        default:true
      },
    },
    data() {
      return {
        active:'',
        tabData:false,
        copyright:false,
        footer_links:false
      };
    },
    mounted() {
        this.copyright = localStorage.getItem('copyright')
        this.footer_links = JSON.parse(localStorage.getItem('footer_links'))
        this.tabData = JSON.parse(localStorage.getItem('tabData'))
        if (!this.tabData) {
          this.getBars();
        }
    },
    methods: {
      getBars() {
      this.$http
          .postData("/Index/bars", { hide_success: true, hide_error: true })
          .then((res) => {
            localStorage.setItem('tabData',JSON.stringify(res))
            this.tabData = { ...res };
          })
          .catch((error) => {
            this.tabData = false;
          });
      },
      toPage(tab) {
        this.active = "";
        if (tab.url == "not") {
          this.$notFound("未开放");
          return;
        }
        if (tab.url.indexOf("tell") >= 0) {
          window.location.href = "tel://" + tab.url.split(":")[1];
          return;
        }
        if (tab.url.indexOf("http") >= 0) {
          window.location.href = tab.url;
          return;
        }
        this.$router.push({ path: tab.url });
      },
      goUrl(tab) {
        if (tab.value == "not") {
          this.$notFound("未开放");
          return;
        }
        if (tab.value.indexOf("tell") >= 0) {
          window.location.href = "tel://" + tab.value.split(":")[1];
          return;
        }
        if (tab.value.indexOf("http") >= 0) {
          window.location.href = tab.value;
          return;
        }
        this.$router.push({ path: tab.value });
    },
    },
  };
  </script>
  
  <style scoped>
  .links{
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    margin-top: 30px;
  }
  .links span{
    margin:0 6px;
  }
  .copyright{
    line-height: 20px;
    font-size: 12px;
    padding:0 20px;
    clear: both;
    margin:10px auto;
  }
  .pmt{
    margin-top:10px;
    text-align: center;
  }
  .pmt .imm{
    width:30px;
    height:30px;
  }
  </style>
  