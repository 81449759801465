<template>
  <div class="hello" style="padding-bottom: 80px">
    <van-sticky v-if="detail">
      <van-nav-bar
        style="background: rgba(49, 151, 253, 1); color: #ffffff"
        @click-left="onClickLeft"
      >
        <template #title
          ><span style="color: #ffffff">{{ detail.title }}</span></template
        >
        <template #left
          ><span style="color: #ffffff">
            <van-icon
              name="arrow-left"
              style="color: #ffffff; margin-right: 6px"
            />返回</span
          ></template
        >
      </van-nav-bar>
    </van-sticky>
    <template v-if="hasLoad && detail">
      <div class="tops" v-if="!detail.tag">
        <div class="title">{{ detail.title }}</div>
        <div class="info">
          <span class="con"><van-icon name="user-o" />管理员</span>
          <span class="con"
            ><van-icon name="clock-o" />时间 {{ detail.times }}</span
          >
        </div>
      </div>
      <div class="content dibb" v-html="detail.content"></div>
    </template>
    <van-empty v-else description="请稍后" />
    <my-footer :view="hasLoad" :showElse="false" :showTabBar="true"></my-footer>
  </div>
</template>
<script>
import { ref } from "vue";
import { showToast } from "vant";
import MyFooter from "@/components/myFooter.vue";

export default {
  name: "makeOrder",
  components: {MyFooter},
  data() {
    return {
      hasLoad: false,
      detail: false,
      tags: false,
    };
  },
  setup() {
    const onClickLeft = () => history.back();
    return {
      onClickLeft,
    };
  },
  mounted() {
    this.tags =
      this.$route.params.tags != null && this.$route.params.tags
        ? this.$route.params.tags
        : false;
    if (!this.tags) {
      showToast("页面不存在");
      setTimeout(() => {
        history.back();
      }, 1500);
      return;
    }
    this.getDetail();
  },
  methods: {
    test(){
      setTimeout(() => {
        document.querySelectorAll("img").forEach((item) => {
          item.style.width = "100%";
        });
      }, 100)
    },
    getDetail() {
      this.$http
        .getDetail({
          table: "Notice",
          tag: this.tags,
        })
        .then((res) => {
          this.detail = res;
          this.hasLoad = true;
          document.title = res.title;
          this.test()
        })
        .catch((error) => {});
    },
  },
};
</script>
  <style scoped>
.content {
  margin: 20px;
  font-size: 12px;
  line-height: 24px;
}
.tops {
  text-align: center;
  margin: 20px;
}
.tops .title {
  font-size: 16px;
  line-height: 30px;
}
.tops .info {
  font-size: 12px;
  line-height: 30px;
  color: #666666;
}
.top .info .con {
  padding: 0 6px;
  font-size: 30px;
}
</style>
  