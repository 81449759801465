<template>
    <div class="hello" style="padding-bottom: 80px;">
        <template v-if="hasLoad">
            <van-sticky>
                <van-nav-bar style="background: #2c92ff;color: #ffffff;" @click-left="onClickLeft">
                    <template #title>
                        <span style="color: #ffffff;" @click="changeMatou">{{ page_title }}</span>
                    </template>
                    <template #left><span style="color: #ffffff;">
                    <van-icon name="arrow-left" style="color:#ffffff;margin-right: 6px;" />返回</span></template>
                </van-nav-bar>
            </van-sticky>
            <div v-if="dataList.length">
                <div class="hot_line" @click="goUrl(item.url_boat)" v-for="(item,idx) in dataList" :key="idx">
                    <div class="wbox">
                        <div class="start">{{ item.matou.up_info.times }}<div style="font-size: 12px;"><span class="bgs">起</span>{{ item.matou.up_info.title }}</div></div>
                        <div class="end">{{ item.matou.down_info.times }}<div style="font-size: 12px;"><span class="bgs">终</span>{{ item.matou.down_info.title }}</div></div>
                        <div class="jt"><span class="arrow"></span></div>
                        <div class="center">{{ item.days }}</div>
                        <div class="trans">{{ item.trans_txt }}</div>
                    </div>
                    <div class="info">
                        <div class="title">
                        {{ item.btitle }} <van-tag type="warning" v-if="item.star">{{item.star}}</van-tag>
                        <span class="price" style="float: right;">￥<b>{{ item.mins }}</b>起</span>
                        </div>
                        <div class="jd"><van-text-ellipsis :content="'团期: '+item.date" /></div>
                    </div>
                </div>
            </div>
            <van-empty v-else image="search" description="没有记录" />
        </template>
        <my-footer :view="hasLoad" :showElse="false" :showBar="true"></my-footer>
    </div>
</template>
<script>
import {ref} from 'vue'
import { showNotify,showToast,BarrageProps, BarrageItem, BarrageInstance } from 'vant';
import MyBox from '@/components/MyBox.vue';
import MyFooter from "@/components/myFooter.vue";
  export default {
    name: 'special',
    components:{
        MyBox,
        MyFooter
    },
    data(){
        return {
            hasLoad:false,
            loading:false,
            tag:false,
            page_title:'专题',
            dataList:[],
        }
    },
    setup() {
        const onClickLeft = () => history.back();
        return {onClickLeft};
    },
    mounted() {
        this.tag = (this.$route.params.tag!=null && this.$route.params.tag) ? this.$route.params.tag : false
        if(!this.tag){
            showToast('缺少参数')
            return
        }
        this.getList()
    },
    methods: {
        getList(){
            this.loading = true
            let form={
                st:'special',// month day boat line 查询类型
                tag:this.tag,
            }
            this.$http.postData('/Index/getShipMent',{...form}).then(res=>{
                this.loading = false
                this.hasLoad = true
                this.dataList = res.list
                this.page_title = `${res.page_title}`
            }).catch(error=>{
                this.loading = false
            })
        },
        goUrl(url){
            if(!url) return
            if(url=='not'){
                this.$notFound('没有执行航线')
                return
            }
            this.$router.push(url)
      },
    },
  }
  </script>
  <style scoped>
  .hot_line{
    background: #ffffff;
    border-radius: 10px;
    margin:10px;
    font-size: 12px;
    box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.3);
}
.hot_line .wbox{
    position: relative;
    height: 65px;
    line-height: 24px;
    overflow: hidden;
}
.hot_line .start{
    position: absolute;
    text-align: center;
    left:10px;
    width:70px;
    top:10px;
    font-size: 20px;
}
.hot_line .end{
    position: absolute;
    text-align: center;
    right:10px;
    top:10px;
    font-size: 20px;
    width:70px;
}
.hot_line .center{
    position: absolute;
    left:0px;
    right:0px;
    top:16px;
    line-height: 20px;
    text-align: center;
}
.hot_line .trans{
    position: absolute;
    left:0px;
    right:0px;
    top:36px;
    line-height: 20px;
    text-align: center;
}
.hot_line .bgs{
    background: #f3f3f3;
    color:#666666;
    display: inline-block;
    line-height: 20px;
    width:20px;
    height:20px;
    font-size: 12px;
    margin-right: 4px;
}
.hot_line .info{
    font-size: 14px;
    border-top:1px dashed #dedede;
    padding-top:10px;
    margin:0 20px;
    height: 50px;
}
.hot_line .info .jd{
    font-size: 12px;
    color: #666666;
    padding-top:5px ;
}
.hot_line .price b{
    color: #ff5a00;
    font-weight: 300;
    font-size: 18px;
}
.hot_line .jt{
    border-bottom: 1px solid #2c92ff;
    position: absolute;
    left:34%;
    right:34%;
    top:32px;
    display: flex;
    justify-content: right;
}
.hot_line .jt .arrow {
 position: relative;
 width: 0;
 height: 0;
 border-style: solid;
 border-width: 5px 0 0px 10px;
 border-color: transparent transparent transparent #2c92ff;
 /* transform: rotate(45deg); */
}
  </style>
  