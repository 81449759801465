<template>
  <div id="app">
    <van-config-provider theme="light">
      <router-view></router-view>
    </van-config-provider>
    <!-- <footer-bar></footer-bar> -->
  </div>
</template>
<script>
import FooterBar from "./components/FooterBar.vue";
export default {
  name: "App",
  components: {
    FooterBar,
  },
  data() {
    return {
      isMobile: true,
    };
  },
  mounted() {
    this.checkMobile();
    if (!this.isMobile) {
      let m = (window.innerWidth - 400) / 2;
      let ftbar = document.querySelector(".van-tabbar--fixed");
      if (ftbar) {
        ftbar.style.width = `400px`;
        ftbar.style.left = `${m}px`;
        ftbar.style.right = `${m}px`;
      }
      let app = document.querySelector("#app");
      app.style.width = `400px`;
      app.style.margin = `0 auto`;
    }
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 767;
      return this.isMobile;
    },
  },
};
</script>

<style>
.dibb p {
  padding: 0px !important;
  margin: 5px 0px !important;
  /* margin-block-start: 0px !important;
  margin-block-end: 0px !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important; */
}
.pc {
  width: 400px;
  margin: 0 auto;
}
</style>
