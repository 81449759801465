import { createApp } from 'vue'
import App from './App.vue'
// 1. 引入你需要的组件
import vant from 'vant';
// 2. 引入组件样式
import 'vant/lib/index.css';

import router from './router'
import apis from '@/libs/apis'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret,faRepeat,faPlayCircle,faSearchPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faRepeat)
library.add(faUserSecret)
library.add(faPlayCircle)
library.add(faSearchPlus)

let app = createApp(App)

app.config.globalProperties.$http = apis
import { showToast } from 'vant';
app.config.globalProperties.$notFound = function(msg='暂不支持'){
    showToast(msg)
}

app.use(router).use(vant).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
