<template>
    <div class="hello" style="padding-bottom: 80px;min-height:100vh;background-color: #f3f3f3;">
        <template v-if="hasLoad">
            <van-sticky>
                <van-nav-bar style="background: #2c92ff;color: #ffffff;" @click-left="onClickLeft">
                    <template #title>
                        <span style="color: #ffffff;" @click="changeMatou">{{ page_title }}</span>
                    </template>
                    <template #left><span style="color: #ffffff;">
                    <van-icon name="arrow-left" style="color:#ffffff;margin-right: 6px;" />返回</span></template>
                </van-nav-bar>
            </van-sticky>
            <van-sticky>
                <div class="filter">
                    <van-search v-model="tmpData.keyword" shape="round" placeholder="请输入搜索关键词" @search="onSearch">
                    </van-search>
                </div>
            </van-sticky>
            <div v-if="dataList.length">
                <van-card v-for="(item,i) in dataList" :key="i"
                    :thumb="item.thumb"
                    :tag="item.trans_txt"
                    @click="goUrl(item.mins?item.mins.url:'not')"
                    class="list">
                    <template #title>
                        <span style="font-size: 1rem;">{{ item.title }}</span>
                    </template>
                    <template #tags>
                        <div v-if="item.tags && item.tags.length">
                            <van-tag plain type="danger" style="margin-right: 4px;" v-for="(tag,t) in item.tags" :key="t">{{tag}}</van-tag>
                        </div>
                    </template>
                    <template #desc>
                        <div style="margin:6px 0;" v-if="item.date_list && item.date_list.length">
                            <van-text-ellipsis rows="1" :content="'团期：'+item.date_list.join('、')"/>
                        </div>
                        <div style="margin:6px 0;" v-else>团期：-</div>
                    </template>
                    <template #num></template>
                    <template #price>
                        <div style="margin:4px auto;">
                            <span class="price" v-if="item.mins">￥<b>{{ item.mins.price }}</b>起</span>
                            <span class="price" v-else><b>电询</b></span>
                        </div>
                    </template>
                </van-card>
            </div>
            <van-empty v-else image="search" description="没有数据" />
        </template>
        <my-footer :view="hasLoad" :showElse="false" :showTabBar="true"></my-footer>

    </div>
</template>
<script>
import {ref} from 'vue'
import { showNotify,showToast,BarrageProps, BarrageItem, BarrageInstance } from 'vant';
import MyBox from '@/components/MyBox.vue';
import MyFooter from "@/components/myFooter.vue";

  export default {
    name: 'makeOrder',
    components:{
        MyBox,
        MyFooter
    },
    data(){
        return {
            hasLoad:false,
            loading:false,
            tmpData:false,
            page_title:'搜索',
            matouArr:[],//码头数组
            dataList:[],
            showdate:false,
            today:{
                date:'',
                week:''
            }
        }
    },
    setup() {
        const onClickLeft = () => history.back();
        return {onClickLeft};
    },
    mounted() {
        let tmp = localStorage.getItem('searchData')
        let matou = localStorage.getItem('matouArr')
        this.matouArr = matou?JSON.parse(matou):[]
        if(!tmp){
            showToast('参数错误')
            return
        }
        this.tmpData = JSON.parse(tmp)
        // 加减数量
        this.tmpData.ns = 0
        // 初始化数据
        this.page_title = `搜索中`
        this.getList()
    },
    methods: {
        onSearch(){
            if(!this.tmpData.keyword){
                showToast('请输入关键词')
                return
            }
            this.getList()
        },
        nextDay(){
            this.tmpData.ns += 1
            this.getList()
        },
        showDateBox(){
            this.showdate = true
        },
        onConfirm(date){
            let m = date.getMonth() + 1
            let d = date.getDate()
            this.tmpData.ns =0
            this.tmpData.date = `${date.getFullYear()}-${m<10?'0'+m:m}-${d<10?'0'+d:d}`
            localStorage.setItem('searchData',JSON.stringify(this.tmpData))
            this.showdate = false
            this.getList()
        },
        changeMatou(){
            let a = this.tmpData.up
            let b = this.tmpData.down
            this.tmpData.up = b
            this.tmpData.down = a
            localStorage.setItem('searchData',JSON.stringify(this.tmpData))
            this.page_title = `${this.tmpData.up.title}<->${this.tmpData.down.title}`
            this.getList()
        },
        getList(){
            this.loading = true
            let form={
                st:'keyword',// month day boat line 查询类型
                keyword:this.tmpData.keyword,
            }
            this.$http.postData('/Index/getShipMent',{...form}).then(res=>{
                this.loading = false
                this.hasLoad = true
                this.dataList = res.list
                this.today = res.today
                this.page_title = `找到${res.list.length}条记录`
            }).catch(error=>{
                this.loading = false
            })
        },
        goUrl(url){
            if(!url) return
            if(url=='not'){
                this.$notFound('没有执行航线')
                return
            }
            this.$router.push(url)
      },
    },
  }
  </script>
  <style scoped>
  .filter{
    background: #f3f3f3;
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.2);
}
.list{
    margin-top:20px;
    background: #ffffff;
}
.list .price{
    font-size: 10px;
}
.list .price b{
    color: #ff5a00;
    font-size: 18px;
    font-weight: 300;
}
  </style>
  