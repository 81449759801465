<template>
    <div class="hello">
      <van-sticky v-if="detail">
        <van-nav-bar
          style="background: rgba(49, 151, 253, 1); color: #ffffff"
          @click-left="onClickLeft"
        >
          <template #title
            ><span style="color: #ffffff">查看订单</span></template
          >
          <template #left
            ><span style="color: #ffffff">
              <van-icon
                name="arrow-left"
                style="color: #ffffff; margin-right: 6px"
              />返回</span
            ></template
          >
        </van-nav-bar>
      </van-sticky>
      <template v-if="hasLoad">
        <div class="tops">
            <van-cell-group inset title="基本信息">
                <van-cell title="单号" :value="detail.order_sn" />
                <van-cell title="状态" :value="detail.status_txt" />
                <van-cell title="游船" :value="detail.title.split('#')[0]" />
            <van-cell title="航线" :value="detail.title.split('#')[1]" />
            <van-cell title="房型" :value="detail.title.split('#')[2]" />
            <van-cell title="出行日期" :value="detail.godate" />
            <van-cell title="创建时间" :value="detail.create_time" />
          </van-cell-group>
          <van-cell-group inset title="预订详情">
            <template v-for="(p,idx) in detail.room.price_list" >
            <van-cell v-if="p.num>0" :key="idx" :title="p.title" :value="'￥'+p.value+'/人*'+p.num" />
            </template>
            <van-cell title="小计"><span class="price">￥<span class="sp">{{ detail.boat_price }}</span></span></van-cell>
          </van-cell-group>
          <van-cell-group inset title="保险">
            <div v-if="hasBaoxian">
            <template v-for="(p,idx) in detail.baoxian">
            <van-cell v-if="p.num>0" :key="idx" :title="p.title" :value="'￥'+p.price+'/人*'+p.num" />
            </template>
            <van-cell title="小计"><span class="price">￥<span class="sp">{{ detail.baoxian_price }}</span></span></van-cell>
        </div>
            <van-cell v-else title="小计"><span class="price">￥<span class="sp">{{ detail.baoxian_price }}</span></span></van-cell>
          </van-cell-group>
          <van-cell-group inset title="其他项目">
            <div v-if="hasGoods">
            <template v-for="(p,idx) in detail.goods" >
            <van-cell v-if="p.num>0" :key="idx" :title="p.title" :value="'￥'+p.price+'/人*'+p.num" />
            </template>
            <van-cell title="小计"><span class="price">￥<span class="sp">{{ detail.goods_price }}</span></span></van-cell>
            </div>
            <van-cell v-else title="小计"><span class="price">￥<span class="sp">{{ detail.goods_price }}</span></span></van-cell>
          </van-cell-group>
          <van-cell-group inset title="旅客信息">
            <template v-for="(p,idx) in detail.user_list" :key="idx">
                <van-cell :title="p.name" :value="p.phone" :label="p.idcard"/>
            </template>
          </van-cell-group>
          <van-cell-group inset title="费用">
            <van-cell title="总金额" :value="detail.total"><span class="price">￥<span class="sp">{{ detail.total }}</span></span></van-cell>
            <van-cell title="优惠" :value="'-'+detail.discount" />
            <van-cell title="实付" :value="detail.pay"><span class="price">￥<span class="sp">{{ detail.pay }}</span></span></van-cell>
            <van-cell title="退款" :value="detail.refund" />
          </van-cell-group>
          <van-cell-group inset title="付款信息">
            <van-cell title="付款方式">{{ detail.pay_type??'未支付' }}</van-cell>
            <van-cell title="付款时间">{{ detail.pay_time??'未支付' }}</van-cell>
            <van-cell title="交易号" :value="detail.trade_no??'未支付'" />
          </van-cell-group>

          <div class="btns" v-if="detail.status==1">
            <van-button type="primary" size="large" @click="goPay">去支付</van-button>
          </div>
        </div>
        <div style="height: 80px;"></div>
      </template>
      <van-empty v-else description="请稍后" />
    </div>
  </template>
  <script>
  import { ref } from "vue";
  import { showToast } from "vant";
  export default {
    name: "makeOrder",
    components: {},
    data() {
      return {
        hasLoad: false,
        detail: false,
        oid: false,
        hasBaoxian:false,
        hasGoods:false
      };
    },
    setup() {
      const onClickLeft = () => history.back();
      return {
        onClickLeft,
      };
    },
    mounted() {
      this.oid =
        this.$route.params.oid != null && this.$route.params.oid
          ? this.$route.params.oid
          : false;
      if (!this.oid) {
        showToast("页面不存在");
        setTimeout(() => {
          history.back();
        }, 1500);
        return;
      }
      this.getDetail();
    },
    methods: {
        goPay(){
            this.$router.push({path:'/order/pay',params:{
                oid:this.oid
            }})
        },
      getDetail() {
        this.$http
          .getDetail({
            table: "Order",
            oid: this.oid,
          })
          .then((res) => {
            this.detail = res;
            this.hasLoad = true;
            let tmp = res.baoxian.filter(item=>{
                return item.num>0
            })
            if(tmp.length>0){
                this.hasBaoxian = true
            }
            let tmp2 = res.goods.filter(item=>{
                return item.num>0
            })
            if(tmp2.length>0){
                this.hasGoods = true
            }
            document.title = '在线支付'
          })
          .catch((error) => {});
      },
    },
  };
  </script>
    <style scoped>
.hello{
    min-height: 100vh;
    background: #f3f3f3;
}
  .tops {
    margin: 20px 0;
  }
  .price{
    color: #cc0000;
    font-size: 12px;
  }
  .price .sp{
    font-size: 14px;
  }
  .btns{
    text-align: center;
    position: fixed;
    bottom: 20px;
    left: 20px;
    right:20px;
  }
  </style>
    