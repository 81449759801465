<template>
    <div class="hello" style="min-height: 100vh;background-color: #ffffff;">
      <van-sticky>
        <van-nav-bar
          style="background: rgba(49, 151, 253, 1); color: #ffffff"
          @click-left="onClickLeft"
        >
          <template #title
            ><span style="color: #ffffff">三峡游轮</span></template
          >
          <template #left
            ><span style="color: #ffffff">
              <van-icon
                name="arrow-left"
                style="color: #ffffff; margin-right: 6px"
              />返回</span
            ></template
          >
        </van-nav-bar>
        
      </van-sticky>
      <template v-if="hasLoad">
        <div class="hot">
            <div class="con">
                <div class="stitle"><van-icon
            name="good-job-o"
            style="color: #cc0000; margin-right: 6px; font-size: 20px"
          />推荐游船</div>
            <van-row gutter="0">
            <template v-for="(item, i) in hotBoat" :key="i">
              <van-col span="8" v-if="i<6">
                <div
                style="
                    font-size: 12px;
                    line-height: 30px;
                    background: #f3f3f3;
                    padding: 0 6px;
                    border-radius: 5px;
                    margin: 5px;
                "
                @click="goUrl(item.url)"
                >
                {{ item.title }}
                </div>
            </van-col>
            </template>
            </van-row>
            </div>
        </div>
        
        


        <van-tabs class="abs" v-model:active="tabActive" sticky>
                <van-tab :title="type.title" v-for="(type,idx) in dataList" :key="idx">
                    <van-divider :style="{color: '#1989fa',borderColor: '#1989fa',padding: '0 10px'}">
                        <van-image fit="cover" :src="type.logo" class="thumb">
                        <template v-slot:loading>
                            <van-loading type="spinner" size="20" />
                        </template>
                        </van-image>
                    </van-divider>
                    
                    <!-- <div class="dibb lcontent van-multi-ellipsis--l2" v-html="type.content"></div>
                    <div class="dibb lcontent" v-if="type.video">
                      <video
                        ref="myPlayer"
                        :src="type.video"
                        t7-video-player-ext="true"
                        webkit-playsinline="true"
                        x5-playsinline="true"
                        controls
                        style="width: 100%; height: 240px"
                      ></video>
                    </div> -->

                    <div v-if="type.boat_list.length">
                        <van-card v-for="(item,i) in type.boat_list" :key="i"
                            :thumb="item.thumb"
                            :tag="item.trans_txt"
                            @click="goUrl(item.mins?item.mins.url:'not')"
                            class="list">
                            <template #title>
                                <span style="font-size: 1rem;">{{ item.title }}</span>
                            </template>
                            <template #tags>
                                <div v-if="item.tags && item.tags.length">
                                    <van-tag plain type="danger" style="margin-right: 4px;" v-for="(tag,t) in item.tags" :key="t">{{tag}}</van-tag>
                                </div>
                            </template>
                            <template #desc>
                                <div style="margin:6px 0;" v-if="item.date_list && item.date_list.length">
                                  <div style="height: 20px;width:100%;white-space:nowrap;overflow: hidden;text-overflow: ellipsis;">{{ '团期：'+item.date_list.join('、') }}</div>
                                    <!-- <van-text-ellipsis rows="1" :content="'团期：'+item.date_list.join('、')"/> -->
                                </div>
                                <div style="margin:6px 0;" v-else>团期：-</div>
                            </template>
                            <template #num></template>
                            <template #price>
                              <div style="margin:4px auto;">
                                  <span class="price" v-if="item.mins">￥<b>{{ item.mins.price }}</b>起</span>
                                  <span class="price" v-else><b>电询</b></span>
                              </div>
                            </template>
                        </van-card>
                    </div>
                    <div class="not" v-else>没有数据</div>
                </van-tab>
        </van-tabs>
      </template>
      <van-empty v-else description="请稍后" />

      <div style="height: 80px;"></div>
      <my-footer :view="hasLoad" :showElse="false" :showTabBar="true"></my-footer>
    </div>
  </template>
  <script>
  import { ref } from "vue";
  import { showToast } from "vant";
  import MyFooter from "@/components/myFooter.vue";

  export default {
    name: "catalogList",
    components: {MyFooter},
    data() {
      return {
        hasLoad: false,
        tabActive:0,
        tags: false,
        loading: false,
        dataList: [],
        per_page: 100,
        last_page: 0,
        current_page: 0,
        total: 0,
        hotBoat:[]
      };
    },
    setup() {
      const onClickLeft = () => history.back();
      return {
        onClickLeft,
      };
    },
    mounted() {
      this.tags =
        this.$route.params.tags != null && this.$route.params.tags
          ? this.$route.params.tags
          : false;
      if (!this.tags) {
        showToast("页面不存在");
        setTimeout(() => {
          history.back();
        }, 1500);
        return;
      }
      this.hotBoat = JSON.parse(localStorage.getItem('hotBoat'))
      this.getDetail();
    },
    methods: {
        goUrl(url){
            if(!url) return
            if(url=='not'){
                this.$notFound('没有执行航线')
                return
            }
            this.$router.push(url)
      },
      getDetail() {
        if (this.loading) {
          return;
        }
        this.loading = true;
        this.$http
          .getList({
            table: "BoatType",
            hide_success: true,
            fields:'id,title,logo,sort,id boat_list,content,video',
            pageInfo: {
              page_size: this.per_page,
            },
            page: this.current_page + 1,
          })
          .then((res) => {
            this.loading = false;
            this.dataList = res.data
            let i=0
            res.data.map((item,idx)=>{
                if(item.id==this.tags){
                    i = idx
                }
            })
            let _this = this
            setTimeout(function(){
                _this.tabActive = i
            },10)
            this.hasLoad = true
          })
          .catch((error) => {
            this.loading = false;
          });
      },
    },
  };
  </script>
<style scoped>
    .thumb{
        width:40px;
        height: 40px;
    }
.hot{
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
}
.hot .con{
    background: #ffffff;
    padding:20px 10px;
    border-radius: 6px;
}
.hot .stitle{
    padding-bottom: 10px;
}

.content{
    margin:0 10px;
}
.van-card{
    padding:10px;
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
}
.not{
    text-align: center;
    color: #666;
    font-size: 12px;
    height: 50px;
    line-height: 50px;
}
.lcontent{
  margin:0 20px;
  font-size: 12px;
  line-height: 24px;
}
.list{
    margin-top:20px;
    background: #ffffff;
}
.list .price{
    font-size: 10px;
}
.list .price b{
    color: #ff5a00;
    font-size: 18px;
    font-weight: 300;
}
  </style>
    