<template>
  <div class="footer_bar" v-if="tabData && view">
    <van-tabbar :style="tabData.style" v-model="active" active-color="#ffffff">
      <van-tabbar-item
        :style="tab.style"
        v-for="(tab, idx) in tabData.list"
        :key="idx"
        :name="tab.name"
        :dot="tab.dot"
        :badge="tab.badge"
        @click="toPage(tab)"
      >
        <template #icon>
          <van-icon
            :name="tab.icon"
            :color="tab.icon_color ? tab.icon_color : ''"
            :size="tab.icon_size ? tab.icon_size : ''"
          />
        </template>
        <span
          :style="{
            color: tab.color ? tab.color : '',
            fontSize: tab.size ? tab.size : '',
          }"
          >{{ tab.title }}</span
        >
      </van-tabbar-item>
    </van-tabbar>
    <!-- <van-back-top right="5vw" bottom="10vh" /> -->
  </div>
</template>

<script>
import { watchEffect } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "FooterBar",
  props: {
    msg: String,
  },
  data() {
    return {
      active: "dd",
      tabData: false,
      view: false,
      tabs: [],
    };
  },
  mounted() {
    if (!this.tabData) {
      this.getBars();
    }
    const route = useRoute();
    watchEffect(() => {
      if (
        route.name == "special" ||
        route.name == "makeOrder" ||
        route.name == "detail" ||
        route.name == "articleDetail" ||
        route.name == "page" ||
        route.name == "catalog" ||
        route.name == "baochuan" ||
        route.name == 'order'
      ) {
        this.view = false;
        return;
      } else {
        this.view = true;
      }
    });
  },
  methods: {
    toPage(tab) {
      this.active = "";
      if (tab.url == "not") {
        this.$notFound("未开放");
        return;
      }
      if (tab.url.indexOf("tell") >= 0) {
        window.location.href = "tel://" + tab.url.split(":")[1];
        return;
      }
      if (tab.url.indexOf("http") >= 0) {
        window.location.href = tab.url;
        return;
      }
      this.$router.push({ path: tab.url });
    },
    getBars() {
      this.$http
        .postData("/Index/bars", { hide_success: true, hide_error: true })
        .then((res) => {
          this.tabData = { ...res };
          this.view = true;
        })
        .catch((error) => {
          this.tabData = false;
        });
    },
  },
};
</script>

<style scoped>
</style>
