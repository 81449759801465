<template>
    <div class="hello" style="min-height: 100vh; background-color: #f3f3f3">
      <div style="padding: 20px">
        <div class="content">
            <van-icon class="icon green" name="success" v-if="status=='success'"/>
            <van-icon class="icon fail" name="cross" v-else/>
            <div class="title">{{ status=='success'?'支付成功':'支付失败' }}</div>
            <van-button type="default" size="small" @click="goUrl('/user')">返回订单</van-button>
            <div>

            </div>
        </div>
      </div>
    </div>
  </template>
    
  <script>
  import { showToast } from "vant";
  
  export default {
    name: "Login",
    data() {
      return {
        loading: false,
        status:'fail',
        oid:false,
      };
    },
    mounted() {
      this.status =this.$route.params.status != null && this.$route.params.status ? this.$route.params.status: 'fail';
      this.oid =this.$route.query.oid != null && this.$route.query.oid ? this.$route.query.oid: false;
    },
    methods: {
      goUrl(url) {
        if(this.oid){
          url = '/order/detail/'+this.oid
        }
        this.$router.push({path:url})
      },
    },
  };
  </script>
<style scoped>
.content{
    text-align: center;
    margin:20px;
    background: #fff;
    border-radius: 10px;
    padding:40px;
}
.content .title{
    height: 40px;
    line-height: 40px;
    font-size: 12px;
}
.content .icon{
    font-size: 60px;
}
.green{
    color: #07c160;
}
.fail{
    color: #ff976a;
}
</style>
    